@charset "utf-8";

@import "variables.scss";

// 定义iconfont
 @font-face {
    font-family: "iconfont";
    src: url("iconfont/iconfont.eot");
    src: url("iconfont/iconfont.svg") format("svg"),
         url("iconfont/iconfont.eot") format("embedded-opentype"),
         url("iconfont/iconfont.ttf") format("truetype"),
         url("iconfont/iconfont.woff") format("woff")
}
.iconfont{ font-family: "iconfont" !important; font-style: normal; -webkit-font-smoothing: antialiased; -webkit-text-stroke-width: 0.2px; -moz-osx-font-smoothing: grayscale; }
.icon-navi_icon_addon:before { content: "\347d"; }
.icon-alert_icon_success:before { content: "\347e"; }
.icon-navi_icon_content:before { content: "\347f"; }
.icon-icon_arrow_more:before { content: "\3480"; }
.icon-navi_icon_controller:before { content: "\3481"; }
.icon-pop_btn_close:before { content: "\3482"; }
.icon-navi_icon_setting:before { content: "\3483"; }
.icon-navi_icon_circle-01:before { content: "\3484"; }
.icon-navi_icon_alert:before { content: "\3485"; }
.icon-navi_icon_error:before { content: "\3486"; }
.icon-navi_yunying:before { content: "\e600"; }
.icon-none:before { content: "\e601"; }
.icon-time:before { content: "\e623"; }
.icon-huodong:before { content: "\e6d8"; }
// Vue相关
[v-cloak]{ display: none; }
// 弹出框[在主内容区域展示]显示隐藏切换效果
.toggle-dialog-transition{ -webkit-transition: all 0.2s ease; transition: all 0.2s ease;}
.toggle-dialog-enter,
.toggle-dialog-leave{ opacity: 0; -webkit-transform: scale(0.9); transform: scale(0.9);}
// 弹出框[在整个窗口展示]显示隐藏切换效果
.toggle-fullpage-overlay-transition{ -webkit-transition: all 0.2s ease; transition: all 0.2s ease;}
.toggle-fullpage-overlay-enter,
.toggle-fullpage-overlay-leave{ opacity: 0; }
// 页面框架结构
.page-container{ position: absolute; left:0; top:0; width:100%; height:100%; }
.bar-nav,
.bar-tree{ float: left; height:100%; position: relative;}
.bar-nav{ width:72px; background-color:$theme-color; text-align: center;}
.bar-tree{ width:180px; background-color:#F5F8F6; }
// 可垂直滚动元素，隐藏掉滚动条
.has-vertical-scrollerbar{ overflow:hidden; overflow-y:scroll;  }
// .has-vertical-scrollerbar::-webkit-scrollbar-track-piece,
// .has-vertical-scrollerbar::-webkit-scrollbar{ width: 2px; }
// 显示自定义样式的滚动条
.show-scrollbar::-webkit-scrollbar-track-piece{ background-color:#fff; width: 5px; border-radius:7px;}
.show-scrollbar::-webkit-scrollbar{ width: 5px; }
.show-scrollbar::-webkit-scrollbar-thumb{ background-color:#eee; }
.show-scrollbar::-webkit-scrollbar-thumb:hover{ background-color:#eee;}
// 左右浮动
.pull-left{ float: left; }
.pull-right{ float: right; }
// 图片容器
.img-box{ display: block; overflow: hidden; position: relative; }

// demo无限旋转动画
@-webkit-keyframes loadingRotateAnimation
{
   0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }

}
@keyframes loadingRotateAnimation
{
   0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
   100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }

}
.loadingRotateAnimation{
  -webkit-animation: loadingRotateAnimation 1s infinite linear;
  animation: loadingRotateAnimation 1s infinite linear;
}


// 侧边一级
.logo{ display: inline-block; width:34px; height:54px; margin:16px 0 0 0; background:url(../imgs/logo.png) no-repeat left top; }
.bar-nav-list{ margin:16px 0 0 0; }
.bar-nav-item{ display: block; padding:12px 0; color:#fff; color:rgba(255,255,255,.7); position: relative;
  &.has-red-dot{
      &:before{ content: ''; position: absolute; top: 10px; right: 20px; border: 4px solid red; border-radius: 4px; }
  }
  &:after{ @include hlg; }
  &:hover{ color:rgba(255,255,255,0.9); }
  &.current{ color:rgba(255,255,255,1); }
  &:hover:after,
  &.current:after{ @include hlg-active; }
  &.config{ position: absolute; left:0; bottom:80px; width:100%; }
  .iconfont{ font-size: 36px; }
  .item-name{ display: block; }
}
.bar-nav-user{ position: absolute; left:0; bottom:20px; width:100%; 
    &:hover~.user-info-overview{ display: block;}
}
// 侧边二级
.outermost-router-view{ position: absolute; left:72px; top:0; right:0; bottom:0; }
.bar-tree-hd{ margin:24px 16px 0 16px; padding:0 0 22px 0; border-bottom: 1px solid $single-line-color; }
.tree-container{ position: absolute; left:0; top:74px; bottom:0; width:100%; padding:0 0 16px 0;
    .btn-create-newgroup{ line-height: 48px; padding-left: 30px;}
}
.tree-item{ display: block; position: relative; color:#666; @include hide-txt; transition: none;
  &.no-child{ color:#333; font-size:14px; }
  &.no-child:before{ content: '\20'; position: absolute; height: 1px; left: 16px; bottom: 0; right:16px; background-color: $single-line-color; }
  &.no-child.current,
  &.no-child:hover{ background-color:rgba(0,0,0,.05); }
}
// 侧边二级 2018.3.26 王同庆
.magx-tree{
    .top-title{ display: block; position: relative; height: 48px; line-height: 48px; padding-left: 16px; font-size: 14px; color: #333; 
        &:after{ content: ""; position: absolute; width: 6px; height: 6px; border-width: 1px 1px 0 0; border-style: solid; border-color: #d4d4d4; transform: rotate(45deg); right: 16px; top: 20px; transition: transform 0.3s; }
    }
    // 南太湖需求2 菜单栏字体大小12px->14px 
    .wrapper{ position: relative; font-size: 14px; 
        &:after{ content: ""; position: absolute; bottom: 0; left: 16px; right: 16px; border-bottom: 1px solid #e8e8e8; }
        &.active{
            .sub-tree{ height: initial; }
            .top-title{
                &:after{ content: ""; position: absolute; width: 6px; height: 6px; border-width: 1px 1px 0 0; border-style: solid; border-color: #d4d4d4; transform: rotate(135deg); right: 16px; top: 20px; }
            }
        }
        &.single{ display: block;
            .top-title{
                &:after{ display: none; }
            }
            &.active{ background-color: #e1e2e2; }
        }
    }
    .sub-tree{ height: 0; overflow: hidden;
        .item{ display: block; padding-left: 35px; height: 44px; line-height: 44px; 
            &:hover, &.current{ background-color: #e1e2e2; }
        }
    }
}


// 主体区域
.main-content{ position: absolute; left:180px; top:0; right:0; bottom:0;
    &.no-secondarybar{ left:0; }
}
.navi-aside{
    .item{ float:left; font-size: 14px; color:#666; padding: 0 6px 10px 6px; margin:0 12px; border-bottom: 2px solid #fff;
        &:hover,
        &.current{ color:$theme-color; border-bottom-color:$theme-color; }
    }
}
.main-top-bar{ padding:22px 0 20px 0; margin:0 18px; border-bottom: 1px solid $single-line-color; position: relative;
  .navi-aside{ position: absolute; right:0; bottom:0; }
}
.main-content-bd{ position: absolute; left:0; top:70px; right:0; bottom:0; padding:18px 18px 64px 18px;
    &.no-title{ top:0; }
}

// 通用组件 开始
.demo-container{ position: absolute; left:0; top:0; width: 100%; height: 100%; padding: 30px;}
.demo-title{ color:$theme-color; font-size: 18px;}
.demo-box{ padding: 24px 0;}

.border-top-arr{ position: absolute; width: 0; height: 0; border-style: solid; border-width: 6px 5px; border-color: #999 transparent transparent transparent; -webkit-transition: all 0.2s; transition: all 0.2s;
    &.turn-up{ transform: rotate(180deg); border-top-color: #666; }
}
.uploaded-imgs-item{ position: relative;
    .uploaded-image{ width: 100%; position: absolute; left:0; top: 50%; -webkit-transform:translate(0, -50%); transform:translate(0, -50%);}
    .kits-wrap{ height: 100%; position: relative; background:$gray-lightest url($base64-icon-img-plhd) no-repeat center center;
        &.has-loaded{ background-image: none;}
    }
    .am-icon-spinner{ position:absolute; left: 0; top:50%; margin-top: -10px; width: 100%; line-height: 20px;  text-align: center; color: #666; }
    .uploaded-file{ height: 100%; background-position: center center; background-repeat: no-repeat; background-size: cover; }
    input[type="file"]{ position: absolute; left:0; top:0; width: 100%; height: 100%; opacity: 0; cursor: pointer;}
    .replace-txt{ display: none; position: absolute; left:0; bottom: 0; width: 100%; color:#fff; text-align: center; background-color: rgba(0,0,0,.6); line-height: 16px; cursor: pointer;  }
    .btn-delete-uploaded{ display: none; position: absolute; right:-6px; top:-6px; z-index: 1; width: 12px; height:12px; background: url($base64-icon-delete-uploaded-img) no-repeat left top; cursor: pointer; }
    &:hover .btn-delete-uploaded{ display: inline; }
    &:hover .replace-txt,
    &:hover .operate-listcell-action{ display: block; }
}
.upload-img-plhd{ position: relative; background:$gray-lightest url($base64-icon-img-plhd) no-repeat center center;
    input[type="file"]{ position: absolute; left:0; top:0; width: 100%; height: 100%; opacity: 0; cursor: pointer;}
}
.upload-file-plhd{ display: inline-block; position: relative; background:$gray-lightest url($base64-icon-file-plhd) no-repeat 16px center; background-size: 17px 14px; padding:0 10px 0 47px;
    input[type="file"]{ position: absolute; left:0; top:0; width: 100%; height: 100%; opacity: 0; cursor: pointer;}
}
.upload-txt{ font-size: 12px; color: #333; }
.magx-upload-multi-imgs{
    .uploaded-imgs-item,
    .upload-img-plhd{ float: left; margin:0 6px 6px 0; }
}
.magx-upload-multi-file{
    .file-name{ margin-left: 20px; color: $theme-color; }
}
.images-hgroup{
    .item{ float: left; margin: 4px 4px 0 0;
        img{ display: block;}
    }
}
.posted-data{ line-height: 20px;
    .txt{ max-height:40px; @include clamp-txt; -webkit-line-clamp: 2; }
}
.magx-form{
    &.common.msgpush{
        .field{ font-weight: bold;}
    }
    .group-fields{ padding: 10px 0; position: relative;
        &:after{ content: '\20'; position: absolute; height: 1px; left: 92px; bottom: 0; right:0; background-color: $single-line-color; }
    }
    .group{ padding: 10px 0;
        &.has-hairline{ border-top: 1px solid #e8e8e8; padding-top: 20px; }
        &:before,
        &:after{ content: " "; display: table; }
        &:after{ clear:both; }
    }
    .field{ float: left; width: 48px; padding-top: 7px; text-align: left; line-height: 16px; margin-right: 18px; }
    .group.one-control .field{ width: auto; }
    .magx-textfield{ width: 302px; }
    .magx-checkbox,
    .magx-radio{ float: left; line-height: 32px; margin-right: 20px; }
    .control-checkboxs,
    .control-radios{ width:640px; }
    .control{ float: left;
        .info{ margin: 10px 0 0 0; clear:both; max-width:640px; line-height: 20px; }
        .cm-audit-source{ border: 1px solid #dedede; padding: 8px; max-height: 200px; overflow-y: scroll;
            .item{ display: inline-block; margin: 0 5px 5px 0; padding: 8px; border: 1px solid #dedede; border-radius: 4px; color: #999; 
                &.is-selected{ background-color: #4A90E0; color: #fff; border: none; } 
            }
        }
    }
    .btn-group{ margin-top: 20px; padding: 20px 0 0 0; border-top: 1px solid $single-line-color;
        .magx-btn{ margin-left: 10px;}
    }
    .single-txt{ line-height: 32px;}
    &.common{
        .field{ width: 92px; text-align: left; margin: 0;}
        .btn-group{ padding-left: 82px;}
        .fixed.btn-group{ background-color: #fff; padding-bottom: 10px; position: fixed; left: 87px; right: 0; bottom: 0; z-index: 1000; }
    }
    &.large{
        .field{ width: 115px; text-align: left; margin: 0;}
        .btn-group{ padding-left: 82px;}
    }
    .magx-dialog{
        .magx-textfield{ width: 100%;}
    }
}
.magx-overlay{ @include overlay;
    &.fixed{ position: fixed; background-color: $overlay-bg-color;
        &.toggle-dialog-enter,
        &.toggle-dialog-leave{ -webkit-transform: scale(1); transform: scale(1); }
    }
}
/* fixed定位dialog */
.magx-dialog-sp{ position: fixed; margin: auto; top: 20%; left: 0; right: 0; padding: 18px 18px 24px 18px; background-color: #fff; border-radius: 12px; box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12); z-index: $z-index-drop-menu; overflow: hidden;
    .btn-close{ position: absolute; right:18px; top:16px; width:24px; height: 24px; background: url($base64-icon-close-dialog) no-repeat center center; cursor: pointer; }
    .btn-group{ margin-top: 10px; padding: 0; border: 0 none;
        .magx-btn{ float: left; margin:0 12px 0 0;}
        .pull-right .magx-btn{ margin:0 0 0 12px;}
    }
    .operation-hd{ padding-bottom: 18px; margin-bottom: 16px; border-bottom: 1px solid $single-line-color; }
}
.magx-dialog{ position: absolute; width:100%; background-color: #fff; border-radius: 12px; box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12); z-index: $z-index-drop-menu; overflow: hidden;
    .btn-close{ position: absolute; right:18px; top:16px; width:24px; height: 24px; background: url($base64-icon-close-dialog) no-repeat center center; cursor: pointer; }
    &.operation{ @include align-hvcenter; padding: 18px 18px 24px 18px;
        .btn-group{ margin-top: 10px; padding: 0; border: 0 none;
            .magx-btn{ float: left; margin:0 12px 0 0;}
            .pull-right .magx-btn{ margin:0 0 0 12px;}
        }
    }
    &.login-dialog{ right: 160px; top: 50%; transform: translateY(-50%); padding: 18px 18px 24px 18px;
        .btn-group{ margin-top: 10px; padding: 0; border: 0 none;
            .magx-btn{ float: left; margin:0 12px 0 0;}
            .pull-right .magx-btn{ margin:0 0 0 12px;}
        }
    }
    .operation-hd{ padding-bottom: 18px; margin-bottom: 16px; border-bottom: 1px solid $single-line-color; }
}
.magx-checkbox{
    label{ position: relative; padding: 0 0 0 24px; }
    .fake-ckb{ position: absolute; width: 12px; height: 12px; left: 0; top: 2px; border:1px solid #979797; border-radius: 2px; }
    input[type="checkbox"]{ position: absolute; left:0; top:2px; visibility: hidden;
        &:checked + .fake-ckb{ border-color:$theme-color; background: $theme-color url($base64-icon-checkbox-arr) no-repeat center center; box-shadow: 0 0 2px 0 rgba(69,188,184,1); }
        &:disabled + .fake-ckb{ opacity: 0.5; cursor: no-drop;}
    }
}
.magx-radio{
    label{ position: relative; padding: 0 0 0 20px; }
    .fake-radio{ position: absolute; width: 12px; height: 12px; left: 0; top: 1px; border:1px solid #979797; border-radius: 6px; -webkit-transition: all $transition-durition; transition: all $transition-durition; }
    input[type="radio"]{ position: absolute; left:0; top:1px; visibility: hidden;
        &:checked + .fake-radio{ border-color:$theme-color; background-color: $theme-color;
            &:after{ content: '\20'; position: absolute; left: 3px; top: 3px; width: 4px; height: 4px; background-color: #fff; border-radius: 4px;}
        }
    }
}
.magx-multi-select{ min-height: 32px; border:1px solid $form-control-border-color; border-radius: 4px; position: relative; cursor: pointer; transition:all $transition-durition; padding-bottom: 4px;
    &.active{ border-color:$theme-color; box-shadow:0 0 4px 0 rgba(66,189,86,.33); }
    .selected-item{ float: left; margin: 4px 0 0 4px; line-height: 20px; padding: 0 12px 0 24px; border:1px solid #E7E7EB; border-radius: 4px; position: relative; background-color: #EFEFEF;
        .icon-close{ position: absolute; left:0; top:0; width:24px; height: 20px; background: #EFEFEF url($base64-icon-close-dialog) no-repeat center 5px; }
    }
    .ipt-w{ margin:0 6px 8px 6px; }
    .magx-dialog{ left:0; top:102%; padding: 8px 0; }
}
.magx-textarea{ border:1px solid $form-control-border-color; border-radius: 4px; padding: 8px 10px;
    &.small{ width: 302px; height: 72px;}
    &.big{ width: 480px; height: 82px;}
}
.magx-select,
.magx-textfield{ width:100%; border:1px solid $form-control-border-color; border-radius: 4px; height: 32px; padding: 8px 10px;}
.magx-textfield.no-border{ border:0 none; background-color: #f4f4f4; border-radius: 2px;
    &:focus{ box-shadow: none; }
    &.timepicker{ padding-right: 32px; background:#f4f4f4 url($base64-icon-timepicker) no-repeat right 12px center; }
}
.magx-timepicker{
    .magx-textfield{ float: left; width: 268px; }
    .divider{ float: left; margin: 0 10px; font-size: 14px; line-height: 32px; }
}
.magx-select{ background-color: #fff;}
.magx-scroll-list{
    &>.magx-scroll-item{ display: block; line-height: 32px; padding: 0 0 0 10px; @include hide-txt;
        &:hover{ background-color: $theme-color; color:#fff; }
        &.active:hover,
        &.active{ background-color:rgba(0,0,0,.05); color:#333; }
    }
}
.magx-dropselect{ line-height: 32px; padding: 0 0 0 12px; position: relative; background-color: #F2F2F2; border-radius: 2px; cursor: pointer;
    .value{ display: block; position: relative; z-index: 1;}
    .border-top-arr{ right:12px; top:14px;
        &.turn-up{ top:7px; }
    }
    .ipt-w{ margin:0 6px 8px 6px; }
    .magx-dialog{ left:0; top:40px; padding: 8px 0; }
}
.magx-btn{ border-radius: 4px; height: 32px; position: relative; overflow: hidden;
    &:after{ @include hlg; }
    &:hover:after{ @include hlg-active; }
    &.disabled{ cursor: not-allowed;}
    &.little{ height: 24px; padding: 0 12px; color:#666; }
    &.mid{ width: 64px; }
    &.big{ width: 94px; }
    &.theme{ color:#fff; background-color: $theme-color; }
    &.warning{ color:#fff; background-color: #F54B48; }
    &.normal{ background-color: #E6E6E6; color:$gray-dark;
        &.disabled{ color:#ccc;
            &:hover:after{ background-color: rgba(0,0,0,0); }
        }
    }
}
.magx-btn-timer{ text-align: left; padding-left: 32px;
  .icon-time{ font-size: 22px; position: absolute; left:9px; top:6px; }
}
.magx-search{ width: 294px;
    .magx-textfield{ float: left; width: 218px;}
    .magx-btn{ margin-left: 12px;}
}
.magx-toolbar .magx-dropselect{ margin: 0 12px 0 0;}
@media screen and (max-width: 1180px){
    .magx-toolbar .magx-dropselect{ width: 120px !important; }
    .magx-toolbar .magx-textfield{ width: 100px !important; }
}
@media screen and (min-width: 1180px) and (max-width: 1366px){
    .magx-toolbar .magx-dropselect{ width: 145px !important; }
    .magx-toolbar .magx-textfield{ width: 130px !important; }
}
@media screen and (min-width: 1366px) and (max-width: 1440px){
    .magx-toolbar .magx-dropselect{ width: 160px !important; }
    .magx-toolbar .magx-textfield{ width: 180px !important; }
}
.magx-tree{
    .tree-item{ line-height: 48px; padding: 0 0 0 16px; }
    &.no-dline .tree-item.no-child:before{ display: none;}
}
.magx-tree-nodline{
  .tree-item.no-child:before{ display: none; }
}
.magx-tree-sub{ line-height: 48px;
    // 南太湖需求2 菜单栏字体大小12px->14px
    &>.item{ display: block; font-size:14px; padding: 0 0 0 30px; position: relative; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
        &.create{ color:$theme-color; cursor: pointer;
            &:hover{ text-decoration: underline; background: none;}
        }
    }
    &>.item:hover,
    &>.item.current{ background-color:rgba(0,0,0,.05); }
    &>.item>.operation{ position: absolute; right:0; top:0; background-color: transparent; color:#999; font-size: 12px; opacity: 0; transition: all 0.2s;
        &:hover{ color:#333; }
        .edit{ display: inline-block; cursor: pointer; 
            img{ vertical-align: middle; }
        }
    }
    &>.item:hover .operation{ display: block; background-color: #e1e2e2; opacity: 1; }

}
.magx-thmub{ overflow: hidden;
    .image{ float: left; border-radius: 4px; margin-right: 10px;}
    .intro{ overflow: hidden;}
    .title{ line-height: 28px; @include hide-txt; }
    &.big-face{ padding: 10px;
        .image{ margin-right: 12px; }
        .title{ line-height: 48px; }
    }
}
.magx-btn-text{ color:$theme-color; font-size: 14px;
    &:hover{ text-decoration: underline; }
    &.disabled{ color:$gray-lighter; cursor: not-allowed; }
    &.disabled:hover{ text-decoration: none; }
}
.magx-table{ table-layout: fixed; width: 100%;
    tbody tr{ border-bottom: 1px solid $single-line-color; }
    thead{ color:#666; }
}
.magx-pager{ margin: 55px 10px 0 20px; color:#666; position:fixed; left:252px; bottom:0; right:0; background-color:#fff; padding:12px 18px; margin:0;
    .magx-btn{ float: left; margin-right: 10px;}
    .num{ line-height: 24px;}
    .num-item{ float: left; margin-left: 18px;}
    .magx-select,
    .magx-textfield{ width: 40px; text-align: center; margin: 0 8px; height: 24px; padding: 4px 0;}
}
.data-chart{
    &>.item>.field{ position: absolute; left:0; top:0; text-align: right; margin-right: 12px; color:$gray-light; @include hide-txt; }
    &>.item{ float: left; width: 50%; line-height: 18px; margin-bottom: 8px; position: relative;}
    &.short-fields{
        &>.item{ padding-left: 60px; }
        &>.field{ width: 48px;}
    }
    &.long-fields{
        &>.item{ padding-left: 84px; }
        &>.field{ width: 72px;}
    }
}
.magx-prompt{ padding: 14px 0 0 102px;  position: relative; min-height: 56px; font-size: 14px;
    .iconfont{ font-size: 80px; position: absolute; left:10px; top:0;}
    .icon-alert_icon_success{ color:#00BD00; }
    .icon-navi_icon_error{ color:#F73508; }
    .icon-navi_icon_error.warning{ color:#FFBF00; }
}
.btn-with-desc{ line-height: 32px;
    .magx-btn{ float: left; margin-right: 12px; }
}
.select-user-indialog{ margin: 12px 0 0 0;
    .recent{ overflow: hidden;
        .info,
        .items,
        .item{ float: left;}
        .item{ margin-right: 20px; color:$theme-color; cursor: pointer;}
    }
    .list{ height: 222px; margin: 14px 0 0 0;
        .item{ float: left; margin: 0 2px 18px 0; width: 60px; text-align: center; cursor: pointer;
            &.selected .item-name{ color:#F03084; }
        }
        .item-name{ line-height: 18px; @include hide-txt; }
    }
}
.magx-overlay-loading{ background-color: #fff; }
.magx-netloading{ width:225px; @include align-hvcenter; text-align: center; box-shadow: none;
    .icon-load { display: inline-block; width: 16px; height: 16px; background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAu5JREFUSA2tlr9LI1EQx/dHIqIxCqlukXCwTSzExs5GtBSMQSzECPoPCBY5rhM7uRTC/QMKiu3Gg1ikCARSpEiTxgtIQCRsGzEWiTHrfWbv1ot6CWfMg2Fe3pv5fmfmvTcbVekxLi4uJp+enqKYLKHNdrttoBXERiqsp9Hnq6ur1W4w6r82MpmM4TjOPs7biM5cQJUuus3e0fDw8F40GrVf470hyGazy0R6CtgYji0kxTyFY3FkZMQFqNVqksks6ysizP3s19Hx9fX1H50kLwgA38HhEEMNsXRdTywuLkopug7LssxGo5HEPoY4GO5ubGx89xyeCXK5nERuYaSoqvp1YWEh6Rn9jz47O0sQ3IH4M2Kbm5tuJi5BPp83AC9jMAb4l/n5+XeBewGcnJwkIPiG1IeGhiKUy9ZkE+B91JimaVa/4IJD1FIqSwJtNpuCqaiFQmGy1WpdM3cgmJqbm+tZc3HqNY6Pj00IfmKjkcVnjR9RyqIDnvoouBBvbW1VwJSbp5NFVAN8CXA5WLmKgxpCoHCuSz6ATUGFpDgodMCLcpsEWzIwECUQCLx5hf0STkxM2H8yMHxSHmEb5FhbW3t+X3JNbSHhJhkDJPkkGRC4rdEOKlIifswOioDDlT4l76siZ5AWAsbKAAmkAQpBWi2VStLzrwF3yGZqenr6Qw+NxmcS8N+HNjMzUwX4iHOQlttXD+rMnKiTlMhP0EcQVd1e5Pf79yCosxC7vLxMdDq8Z35/fy8dNYZPHZI98XUJIpGIDXgcEgd9UC6X301yd3fntWuH6OOjo6Puu3q+r8IG8A7qUIgZFp/BRDgc7nkmt7e3JoBS2pgEiN4dHx9/+8Fhwx1XV1fLpHlKJtK+W0hKxOfzFemOv6Pi9ctVZH1FBFs/UscmHgwGu38yPZKbmxvj4eFhH+dt1nScJSVXXs+5IG3W3I++VxYPR/SLEnVuyLxarU4+Pj66f1sgMBG3bwEoZ1YBPI2ch0Khrn9bfgG54qYxpJVU1AAAAABJRU5ErkJggg==") no-repeat left top;  background-size: 16px 16px; -webkit-animation: loadingRotateAnimation 1s infinite linear; animation: loadingRotateAnimation 1s infinite linear;
    }
    .site-slogan{ color:$gray-dark; font-size:16px; margin-top: 24px;
        .img{ background-color: #fff; position: relative; top:2px; }
    }
}
.magx-nullcontent{ text-align: center; padding: 120px 0; color:$gray-light;
    .iconfont{ display: inline-block; margin-bottom: 20px; font-size:160px; color:$gray-lightest; }
}
.magx-partloading{ text-align: center; color:$gray-light; position: absolute; left:0; top:0; width:100%; height: 100%; background-color: #fff;
    .note-area{ position: absolute; @include align-hvcenter;}
    .icon-load { display: inline-block; width: 16px; height: 16px; background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAu5JREFUSA2tlr9LI1EQx/dHIqIxCqlukXCwTSzExs5GtBSMQSzECPoPCBY5rhM7uRTC/QMKiu3Gg1ikCARSpEiTxgtIQCRsGzEWiTHrfWbv1ot6CWfMg2Fe3pv5fmfmvTcbVekxLi4uJp+enqKYLKHNdrttoBXERiqsp9Hnq6ur1W4w6r82MpmM4TjOPs7biM5cQJUuus3e0fDw8F40GrVf470hyGazy0R6CtgYji0kxTyFY3FkZMQFqNVqksks6ysizP3s19Hx9fX1H50kLwgA38HhEEMNsXRdTywuLkopug7LssxGo5HEPoY4GO5ubGx89xyeCXK5nERuYaSoqvp1YWEh6Rn9jz47O0sQ3IH4M2Kbm5tuJi5BPp83AC9jMAb4l/n5+XeBewGcnJwkIPiG1IeGhiKUy9ZkE+B91JimaVa/4IJD1FIqSwJtNpuCqaiFQmGy1WpdM3cgmJqbm+tZc3HqNY6Pj00IfmKjkcVnjR9RyqIDnvoouBBvbW1VwJSbp5NFVAN8CXA5WLmKgxpCoHCuSz6ATUGFpDgodMCLcpsEWzIwECUQCLx5hf0STkxM2H8yMHxSHmEb5FhbW3t+X3JNbSHhJhkDJPkkGRC4rdEOKlIifswOioDDlT4l76siZ5AWAsbKAAmkAQpBWi2VStLzrwF3yGZqenr6Qw+NxmcS8N+HNjMzUwX4iHOQlttXD+rMnKiTlMhP0EcQVd1e5Pf79yCosxC7vLxMdDq8Z35/fy8dNYZPHZI98XUJIpGIDXgcEgd9UC6X301yd3fntWuH6OOjo6Puu3q+r8IG8A7qUIgZFp/BRDgc7nkmt7e3JoBS2pgEiN4dHx9/+8Fhwx1XV1fLpHlKJtK+W0hKxOfzFemOv6Pi9ctVZH1FBFs/UscmHgwGu38yPZKbmxvj4eFhH+dt1nScJSVXXs+5IG3W3I++VxYPR/SLEnVuyLxarU4+Pj66f1sgMBG3bwEoZ1YBPI2ch0Khrn9bfgG54qYxpJVU1AAAAABJRU5ErkJggg==") no-repeat left top;  background-size: 16px 16px; -webkit-animation: loadingRotateAnimation 1s infinite linear; animation: loadingRotateAnimation 1s infinite linear; margin-bottom: 8px;
    }
}
.rich-text-wrapper{ width: 916px; }

.operate-listcell{ position: absolute; right:0; bottom:0; }
.operate-listcell-action{ display: none; line-height: 21px; padding: 0 4px; color:#fff; background-color: rgba(0,0,0,.7); cursor: pointer; margin-top: 1px; font-size: 12px; }

.color-match-card{ display: inline-block; font-size:0; margin:0 8px 0 2px; position: relative; top:1px;
    .color-item{ width: 12px; height: 12px; float: left; margin:0 0 0 5px; border-radius: 6px;}
}
.color-picker{ position: relative;
    input[type="color"]{ position: absolute; left:0; top:0; width: 100%; height: 100%; opacity: 0;}
    .fake-ipt{ width: 22px; height: 22px; border:1px solid rgba(0,0,0,.1); border-radius: 11px;}
}

.multi-type-form{
  .magx-btn-text-addnew{ font-size: 12px; margin:10px 0 0 0;
    &.for-group-fields{ margin:16px 0 0 92px;}
  }
  &>.item{ padding: 16px 0; margin-bottom: 12px; border-bottom: 1px solid $single-line-color;
     &:last-child{ border: 0 none; }
  }
  .color-picker{ float: left; margin: 3px 24px 0 14px;}
  .check-example{ margin-left: 14px; }
}
.vspacer20{ height: 20px; overflow: hidden; font-size: 0;}

.magx-func-switch{ width:952px; background-color: #F4F5F9; padding: 18px 146px 24px 72px; position: relative;
    .icon{ position: absolute; left:22px; top: 50%; -webkit-transform:translate(0, -50%); transform:translate(0, -50%); }
    .magx-btn{ position: absolute; right:32px; top: 50%; -webkit-transform:translate(0, -50%); transform:translate(0, -50%); }
    .title{ font-size: 14px; line-height: 22px;}
}

.magx-func-list{ width:700px;
    li{ padding: 20px 0; position: relative; min-height: 48px;
        &:after{ content: '\20'; position: absolute; height: 1px; left: 70px; bottom: 0; right:0; background-color: $single-line-color; }
        &:last-child:after{ display: none; }
    }
    .icon{ float: left; width: 48px; height: 48px; border-radius: 6px; margin-right: 22px; }
    .content{ overflow: hidden; margin-right:120px; }
    .name{ font-size: 14px; line-height: 24px; font-weight: bold;}
    .magx-btn{ position: absolute; right:24px; top: 24px; }
}


.magx-group-list{
    .item{ padding: 20px 130px 20px 0; border-bottom: 1px solid $single-line-color; position: relative;}
    .magx-thmub .image{ margin-right: 18px;}
    .magx-btn{ position: absolute; right:28px; top:38px;}
    .desc{ width:700px; line-height: 1.6; }
}


.magx-sum-panel{ overflow: hidden; text-align: center; line-height: 1.6;
    .bd{ float: left; background-color: #F8F8F8; padding: 18px 0 13px 0; }
    .item{ width: 300px; float: left; position: relative;
        &:after{ content: '\20'; position: absolute; width: 1px; height: 44px; background-color: #dedede; right: 0; top: 11px; }
        &:last-child:after{ display: none; }
    }
    .title{ font-size: 16px; }
    .number{ font-size: 28px; color:#598FDB; }
}

.magx-tab{ border-bottom: 1px solid $single-line-color;
    .navi-aside{ float: left; margin-left: -12px; }
}

.bg-admin-login{ background-color: #42bd56; background-size: cover; background-position: center center; background-repeat: repeat-y;
    .magx-dialog{ width: 316px; padding: 0;
        .magx-form{ padding: 18px 18px 24px 18px; }
        .hd{ text-align: center; padding: 18px 0; background-color: $theme-color; color:#fff; font-size: 20px; }
    }
    .magx-form .magx-textfield{ width: 280px;
        &.valcode{ width:184px; float: left; margin-right: 16px; }
    }
    .magx-form .magx-btn{ width: 100%; height: 40px; font-size: 16px; }
}

.magx-input-text-switch{
    .magx-textfield{ width:64px; transition: all 0s;
        &.hasval{ border-color:#fff; 
            &::-webkit-inner-spin-button{ opacity: 1; }
        }
        &.hasval:focus{ border-color:$theme-color; }
    }
}

.magx-help-bubble{ position: absolute; right:2px; bottom:130px; font-size: 14px;
  .btn-hover{ display: block; width: 36px; text-align: center; padding: 12px 10px; color:#666; box-shadow: 0 0 6px 0 rgba(0,0,0,.12); background-color: #fff; cursor: pointer;}
  .magx-overlay.fixed{ background-color: rgba(0,0,0,0);}
  .magx-dialog{ width: 365px; right:48px; bottom:0; padding: 20px; }
  .help-hd{ position: relative; line-height: 20px;
    .link{ font-size: 12px; color:#666; position: absolute; right: 0; top:0; }
    .link:hover{ color:#3A6597; text-decoration: underline; }
  }
  .help-title{ font-weight: bold; font-size: 14px;}
  .help-bd{ max-height: 190px; padding:0 0 10px 0; margin-top: 16px;
    li{ margin-bottom: 14px; }
    a{ display: block; line-height: 22px; padding-left: 10px; position: relative; max-height: 44px; overflow: hidden; color:#3A6597; @include clamp-txt; -webkit-line-clamp: 2;
        &:hover{ text-decoration: underline;}
        &:after{ content: '\20'; position: absolute; width: 2px; height: 2px; left: 0; top: 12px; background-color: #3A6597; border-radius: 2px; }
    }
  }
}

.magx-form-cols{ overflow: hidden; position: relative; padding-bottom: 44px; border-bottom: 1px solid $single-line-color;
    &.col-2 .layout-item{ width: 50%; float:left; padding-right: 20px; }
    &.col-2 .layout-item:first-child{ position: relative;
        &:before{ content:" "; position: absolute; right:20px; top:0; bottom:44px; width:1px; background-color: $single-line-color; }
    }
    &.col-2 .control{ float: none; overflow: hidden; }
    .info-note{ color:#999; line-height: 32px; }
    .img-qrcode{ display: block; margin-left: 154px; }
    .multi-fields{ line-height: 22px; padding-top: 5px; }
}

.magx-border-btns{ overflow: hidden; margin: 10px 0;
  &>.btn{ float: left; padding: 0 4px; height: 20px; line-height: 20px; border:1px solid $theme-color;  margin:0 8px 6px 0; color: $theme-color; cursor: pointer; }
}

/*  报表的一些样式  */
.magx-chart{ border:1px solid #ddd; font-size: 14px; margin-bottom: 20px;
  .magx-dropselect{ font-size: 12px; }
}
.magx-chart-hd{ line-height: 48px; position: relative; background-color: #F6F8F6; padding: 0 20px; box-shadow: 0 1px 0 0 rgba(237,237,237,1);
  .icon-issue{ float: left; width: 18px; height: 18px; background-color: #C8C8C8; border-radius: 9px; color:#fff; line-height: 18px; text-align: center; font-size: 12px; margin: 14px 0 0 10px; cursor: pointer; }
  .export-exel{ color:#0D7DFF; }
}
.magx-chart-bd{ padding: 20px;}
.magx-chart-fields-less{ margin-top: 20px; padding-top: 20px; border-top: 1px solid rgba(0,0,0,.1); }
.magx-chart-fields{ display: flex;
  &.align-center{ text-align: center;
    .item{ padding-left:0; margin-left: 0; }
  }
  &.has-padding{ margin-right: 15%; }
  .magx-btn-text{ margin-left: 16px;}
  .item{ padding: 10px 0 10px 20px; margin-left: -20px; flex:1; border-left:1px solid rgba(0,0,0,.12); position: relative;
    &:first-child{ border:0 none; }
  }
  .item-longer{ flex:2; }
  .note{ color:#999; margin-left: 12px; }
  .num{ color:#3A403B; font-size: 32px; line-height: 46px; margin-top: 8px;}
  .magx-dialog.ticket-number{ display: none; width: 206px; padding: 22px 24px; left: 100px;
    .data-row{ overflow: hidden; line-height: 36px;}
    .data-row-name{ color:#666; }
    .data-row-count{ color:#0076FF; float: right;}
  }
}
.magx-list-bycol{ display:flex; margin: 0 -12px;
  .col{ flex:1; margin:0 12px; }
  .magx-pager{ position:static; padding:20px 0; }
}


/*  magx中用户标签的样式 */
.content-with-usertag{ padding-right: 256px; position: relative; }
.usertag-list{ position: fixed; right:0; top:148px; bottom:0; width: 224px; padding-bottom: 20px; border-left: 1px solid $single-line-color; }
.usertag-list-item{ overflow: hidden; line-height: 30px; padding:0 12px 0 20px; margin-bottom: 8px;  cursor: pointer;
  &.active,
  &:hover{ background-color: #F6F6F6;}
  .name,
  .count{ float: left;}
  .name{ margin-right: 10px; @include hide-txt; max-width: 100px;}
  .magx-btn-text,
  .btn-delete{float: right; font-size: 12px;}
  .btn-delete{ color:#666; display: none;}
  &:hover .btn-delete{ display: block;}
}
.add-usertag-btn{ width: 106px; height: 32px; border:1px solid #E7E7EB; background: #F4F4F4 url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAINJREFUSA3tl0EKwCAMBE3p6/rH5Bft91Jz8b67Bw9GEER2UEcwOAbZIuKtTuLjZsHMfFi2uEuBFbYXVuxBbKuGdCnhVq3Yg9jzVFtVGPXBhxzPsJl9W1TPgya62ZV396y+JsDBlhPXHnth8Kb4eKvm3YFkqwaF8fHzVNN/pyptSpX5ARoCHY3tUyrwAAAAAElFTkSuQmCC") no-repeat center center; background-size: 14px 14px; cursor: pointer; margin: 0 10px 10px 0; float: left;
  &:hover{ opacity: 0.8; }
}
.add-usertag{
  .added-item{ float: left; margin: 0 10px 10px 0; min-width: 106px; height: 32px; border:1px solid #E7E7EB;     padding: 0 10px; line-height: 32px; text-align: center; position: relative;}
  .btn-delete-added{ position: absolute; right:-7px; top:-7px; width: 14px; height: 14px; border-radius: 7px; cursor: pointer; background: #AEAEAE url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAHCAYAAADEUlfTAAAAAXNSR0IArs4c6QAAAF5JREFUCB1VjkENgDAQBOsADVVCLWIDBzjgiw0c8Coz5bhAk0k33b29lt77CnOJg55gg1o04IxbY4flDRtoYOD4GSZ4cELjgvadyioNsOEJIFyeOzTAwPhQzZoQw0DfC4RukcbmdjkAAAAASUVORK5CYII=") no-repeat center center;}
}

.icon-text-comp{ position: relative; display: inline-block; margin-right: 24px; padding-left: 60px; padding-right: 24px;
    .magx-textfield{ width: 152px; display: block; }
    &:after{ content: ""; height: 50px; position: absolute; right: 0; top: 10px; border-right: 1px solid #e9e9e9;  }
    &:last-child{
        &:after{ display: none; }
    }
}

// 通用组件 结束

// 2018.3.30 王同庆  用户信息/退出帐号
.user-info-overview{ display: none; width: 210px; height: 115px; position: absolute; bottom: 10px; left: 62px; border-radius: 4px; box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.08); z-index: 3; background-color: #f4f4f4; text-align: left;
    &:hover{ display: block; }
    .user-info{ padding: 15px 0 20px 15px; 
        .avatar{ width: 32px; height: 32px; margin-right: 10px; border-radius: 32px; float: left; }
        .info{ overflow: hidden; 
            .username{ line-height: 18px; font-size: 14px; color: #333; }
            .role{ color: #999; font-size: 12px; }
        }
    }
    .signout{ position: relative; height: 50px; line-height: 50px; padding-left: 20px; font-size: 14px;
        &:before{ content: ''; position: absolute; top: 0; left: 10px; right: 10px; border-top: 1px solid #e9e9e9; }
    }
}

.magx-switch{ position: relative; padding: 0 20px 0 10px; line-height: 30px; margin-left: 25px;
    .border-top-arr{ width: 0; height: 0; position: absolute; top: 5px; right: 0; border-style: solid; border-width: 6px 5px; border-color: #999 transparent transparent transparent; -webkit-transition: all 0.2s; transition: all 0.2s; transform-origin: center 3px; }
    &.turn-up{  
        .border-top-arr{ transform: rotate(180deg); }
    }
}

//信息发布 2018.5.31
.cm-list{ border-collapse: collapse; }
.cm-list, .cm-list tr, .cm-list th, .cm-list td{ border: 1px solid #F0F0F0; }
.cm-list tr th{ padding: 20px 0; text-align: center; }
.cm-list tbody tr:hover{ background-color: #F7F7F7; }
.cm-list tbody tr td:first-child span{ font-weight: bold; font-size: 14px; }
.cm-list tbody tr td:first-child:hover .cm-list-index{ display: none; }
.cm-list tbody tr td:first-child:hover .cm-list-check{ display: inline-block; }
.cm-list-check{ display: none; }
.cm-list-check:checked{ display: inline-block; }
.cm-list-check:checked ~ span.cm-list-index{ display: none; }
.cm-list-item.mixed{ position: relative; height: 118px; padding-right: 180px; 
    .cm-list-item_album{ width: 160px; height: 118px; position: absolute; right: 0; top: 0; margin: 0; }
    .cm-list-item_footer{ position: absolute; bottom: 0; left: 0; right: 180px; }
}
.cm-list-item.plain{
    .cm-list-item_title{ margin-bottom: 20px; }
}
.cm-list-item_title{ list-style: none;
    .content{ display: inline-block; max-height: 44px; overflow: hidden; font-size: 16px; color: #1B1B1B; font-weight: normal; 
        &:hover{ cursor: pointer; }
    }
    .source{ display: inline-block; padding: 2px 6px; background-color: #F0F0F0; vertical-align: top; font-size: 12px; color: #8C8C8C; }
}
.cm-list-item_album{ display: flex; margin: 10px 0 14px;
    li{ background-size: cover; background-position: center; background-repeat: no-repeat; }
    .item{ width: 160px; height: 118px; margin-right: 8px; }
    .single{ width: 100%; height: 118px; }
}
.cm-list-item_footer{
    .section{ margin-bottom: 17px; font-size: 14px; color: #8C8C8C;
        .user{ color: #84A3C2; }
    }
    .addtion{ border: 1px solid #36A6E9; border-radius: 4px; padding: 0 8px; color: #4CC3F2; }
}
.cm-list-addtion{ margin-bottom: 17px; font-size: 12px; color: #545454; text-align: left;
    .field{ min-width: 48px; margin-right: 36px; float: left; font-size: 14px; color: #545454; }
    .value{ font-size: 14px; margin-right: 22px;}
    .status{ display: inline-block; width: 66px; height: 22px; line-height: 22px; color: #fff; text-align: center;
        &.audit{ background-color: #FBBC3F; }
        &.allowed{ background-color: #3DB6B0; }
        &.not-allowed{ background-color: #E04A4A; }
    }
    .time-pick{
        &:hover{ background-color: transparent; }
    }
    .audit-source-wrapper{ overflow: hidden;
        .item{ display: inline-block; margin-bottom: 5px; margin-right: 5px; padding: 8px; border: 1px solid #dedede; border-radius: 4px; color: #999;
            &.is-selected{ background-color: #4A90E0; color: #fff; border: none; }
        }
        .more-btn{ margin-top: 8px; color: #42bd56; font-size: 12px; }
    }
}
.cm-list-btn-wrapper{ display: flex; width: 100%; margin: 0 auto; font-size: 0; justify-content: center; flex-wrap: wrap; }
.cm-list-btn{ width: 90px; height: 32px; border-radius: 2px; margin: 5px 5px 5px 0; font-size: 12px; color: #fff;
    &.fix{ background-color: #3DB6B0; }
    &.shield, &.red{ background-color: #E04A4A; }
    &.gray{ background-color: #B3B3B3; }
    &.operation{ background-color: #4A90E0; }
}

@media screen and (max-width: 1280px){
    .cm-list-btn{ width: 40%; margin-right: 5px; }
}

// 跳转选择器
.magx-selectform{ width: 949px; height: 530px; position: relative; background-color: #fff; overflow: hidden; z-index: 6;
    .magx-tree{ width: 128px; height: 100%; float: left; background-color: #F5F7F5; overflow-y: scroll; 
        .single.item{ display: block; position: relative; height: 48px; line-height: 48px; padding-left: 16px; font-size: 14px; color: #333;
            &:after{ content: ""; position: absolute; bottom: 0; left: 16px; right: 16px; border-bottom: 1px solid #e8e8e8; }
            &.current{ background-color: #e1e2e2; }
        }
    }
    .magx-selectform_content{ margin-left: 128px; padding: 14px 20px 20px 14px; }
    .close-btn-wrapper{ position: absolute; right: 0; top: 0; padding: 14px 14px 0 0; }
}
.magx-selectform_content{
    .magx-table{ max-height: 366px; overflow-y: scroll;
        table{ width: 100%; }
        thead{ background-color: rgba(0, 0, 0, 0.03); font-size: 12px; color: #999; 
            tr{ height: 32px; text-align: left; }
            th{ vertical-align: middle; font-weight: normal;
                &:first-child{ padding-left: 16px; }
            }
        }
        tbody{
            td:first-child{ padding: 10px 0 8px; border-bottom: 1px solid #e9e9e9; }
        }
        .title{ max-height: 40px; margin-bottom: 6px; line-height: 20px; overflow: hidden; font-weight: bold; font-size: 14px; color: #333; }
        .sub-title{ margin: 4px 0 6px; font-size: 12px; color: #666; }
        .preview-btn{ text-decoration: underline; color: #3EC154; font-size: 12px; }
        .defailt-result{ text-align: center; font-size: 12px; color: #999; 
            .icon{ margin: 80px 0 44px; }
        }
    }
    .addtion-info{ font-size: 12px;
        .field{ margin-right: 15px; }
        .id{ color: #999; }
        .username{ color: #607EA6; }
        .author{ color: #333; }
        .source{ color: #333; }
        .time{ color: #999; }
        .status{ display: inline-block; width: 44px; border-radius: 18px; font-size: 10px; color: #fff; text-align: center;
            &.not-started{ background-color: #E24845; }
            &.normal, &.started{ background-color: #42BD56; }
            &.deleted, &.end{ background-color: #B3B3B3; }
        }
    }
    .component-normal{ padding-left: 15px; }
    .component-tools{ max-height: 459px; overflow-y: scroll; 
        .title{ margin-bottom: 10px; font-size: 12px; color: #999; }
        .tools{ display: flex; flex-wrap: wrap; margin-bottom: 28px; border-width: 1px 0 0 1px; border-style: solid; border-color: #e9e9e9;
            .item{ box-sizing: border-box; width: 33.33%; padding: 0 24px 0 20px; line-height: 36px; border-width: 0 1px 1px 0; border-style: solid; border-color: #e9e9e9; }
            .tool-name{ font-size: 14px; color: #545454; }
            .preview-btn{ text-decoration: underline; float: right; color: #3EC154; font-size: 12px; }
        }
    }
    .mix-content{ position: relative; padding-left: 60px;
        .thumb{ width: 36px; height: 36px; position: absolute; left: 13px; top: 4px; background-size: cover; background-position: center; 
            .icon-cert{ width: 16px; position: absolute; right: -4px; bottom: 4px; z-index: 4;  }
        }
        .user-info{
            .username{ margin-right: 5px; font-size: 12px; color: #545454; }
            .icon-level{ height: 16px; margin-right: 5px; }
        }
    }
    .component-user{ padding: 10px 0 10px 15px;
        .thumb{ border-radius: 100%; top: 0; 
            .icon-cert{ bottom: 0; }
        }
        .user-info{ margin-bottom: 5px; line-height: 16px; }
    }
}

// 预览弹窗
.selectform-preview{ width: 566px; height: 363px; position: fixed; top: 0; bottom: 0; left: 0; right: 0; margin: auto; background-color: #fff; box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.16); z-index: 6;
    .title{ position: relative; line-height: 46px; padding: 0 27px 0 15px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden; font-size: 14px;
        &:after{ content: ""; position: absolute; bottom: 0; left: 15px; right: 15px; border-bottom: 1px solid #e9e9e9; }
    }
    .magx-btn{ position: absolute; right: 22px; top: 30px; }
    .close-btn-wrapper{ position: absolute; right: 0; top: 0; padding: 14px 14px 0 0; }
}
.selectform-preview_list{ padding: 0 22px 0 32px;
    .item{ position: relative; height: 143px; padding: 20px 0 20px 134px; 
        &:first-child{ border-bottom: 1px solid #e9e9e9; }
    }
    .qrcode{ position: absolute; left: 17px; top: 20px; }
    .list-title{ line-height: 24px; font-weight: bold; font-size: 14px; color: #111; }
    .note{ margin: 9px 0 7px; font-size: 12px; color: #999; }
    .radio-box{ line-height: 30px; font-size: 12px; color: #111; }
}

//0719 南太湖需求 字体统一增大
.cm-list-0719{ font-size: 14px; }
.cm-list-0828{ 
    &.magx-table{
        tbody{
            tr{
                td:nth-child(n+2){ vertical-align: top; }
            }
        }
    }
    .audit-list-item_header{ padding-left: 0; 
        .user-info-header{ margin-bottom: 16px; padding-left: 46px;
            .userinfo{ box-sizing: border-box; min-height: 36px; padding-top: 9px; }
        }
    }

}
.cm-list-0828_addtion{ width: 186px; margin: 0 auto 5px;
    .field{ display: block; margin-bottom: 3px; text-align: left; font-weight: bold; color: #666; }
    .item{ margin-bottom: 5px; 
        .select-btn[type="checkbox"]{ width: 12px; height: 12px; margin-right: 5px; border: 1px solid #979797; border-radius: 2px; background-position: center; cursor: pointer; -webkit-appearance: none; vertical-align: middle;
            &:checked{ border-color: $theme-color; background: url($base64-icon-checkbox-arr) no-repeat $theme-color; background-position: center; box-shadow: 0 0 2px 0 rgba(69,188,184,1); }
          }
    }
}
.cm-list-0828_addtion-wrapper{ width: 100%; display: flex; flex-wrap: wrap; justify-content: space-around; text-align: left; 
    .item{ min-width: 90px; text-align: left; }
    .value{ margin-right: 10px; }
}

// 用户选择器
.magx-userselector{ display: flex; justify-content: space-between; flex-wrap: wrap; }
.magx-userselector-item{ position: relative; box-sizing: border-box; width: 288px; margin-bottom: 14px; padding: 14px 12px 14px 59px; border: 1px solid #e7e7e7; background-color: #FBFBFB;
    .avatar{ width: 36px; height: 36px; border-radius: 36px; background-size: cover; background-position: center; background-repeat: no-repeat; position: absolute; left: 14px; top: 14px;  }
    .des{ min-height: 18px; font-size: 12px; color: #999; }
}
.magx-userselector-item_content{ margin-bottom: 5px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
    .username{ font-size: 12px; color: #545454; }
    .sex{ height: 12px; vertical-align: middle; }
    .overview{ font-size: 12px; color: #999; }
}

// 快捷评论
.magx-reply-direct{ display: flex; justify-content: space-between; flex-wrap: wrap; }
.magx-reply-direct-item{ position: relative; width: 300px; padding-top: 14px; padding-bottom: 10px; padding-left: 48px; border-bottom: 1px solid #e7e7e7;
    .avatar{ width: 32px; height: 32px; border-radius: 32px; background-size: cover; background-position: center; background-repeat: no-repeat; position: absolute; left: 0; top: 14px; }
    .content{ width: 100%; height: 32px; line-height: 32px; font-size: 12px; color: #333; 
        &:-webkit-input-placeholder{ color: #999; }
        &:focus{ border: none; box-shadow: none; color: #333; }
    }
}

.operation-bd{ padding: 0 0 22px 31px; position: absolute; bottom: 0; left: 0; right: 0; }